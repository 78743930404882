var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex flex-row justify-between items-center mb-8" },
        [
          _c(
            "vs-select",
            {
              model: {
                value: _vm.selectedLanguage,
                callback: function($$v) {
                  _vm.selectedLanguage = $$v
                },
                expression: "selectedLanguage"
              }
            },
            _vm._l(_vm.languages, function(language) {
              return _c("vs-select-item", {
                key: language,
                attrs: { name: "language", value: language, text: language }
              })
            }),
            1
          ),
          _c(
            "button",
            {
              staticClass: "sk-btn sk-btn-primary rounded-lg",
              on: { click: _vm.handleDeploy }
            },
            [_vm._v(" Deploy ")]
          )
        ],
        1
      ),
      _c("DataTable", {
        attrs: {
          config: _vm.datatableConfig,
          elements: _vm.translations[_vm.selectedLanguage],
          stripe: true,
          draggable: false,
          sst: false,
          pagination: _vm.pagination
        },
        on: { action: _vm.handleAction }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }