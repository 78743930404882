import axios from '@/axios.js'

export default {
  build : async () => {
    try {
      await axios.adminapi.post(`/v4/projects/console/build`)
    } catch (error) {
        throw new Error(error)
    }
  },
}
