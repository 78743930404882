<template>
  <div>
    <div class="flex flex-row justify-between items-center mb-8">
        <vs-select v-model="selectedLanguage">
            <vs-select-item name="language" :key="language" :value="language" :text="language" v-for="language in languages"/>
        </vs-select>
        <button @click="handleDeploy" class="sk-btn sk-btn-primary rounded-lg">
            Deploy
        </button>
    </div>

    <DataTable
        :config="datatableConfig"
        :elements="translations[selectedLanguage]"
        :stripe="true"
        :draggable="false"
        :sst="false"
        :pagination="pagination"
        @action="handleAction"
    />
  </div>
</template>

<script>

import ca from "@/messages/ca.json";
import de from "@/messages/de.json";
import en from "@/messages/en.json";
import es from "@/messages/es.json";
import fr from "@/messages/fr.json";
import it from "@/messages/it.json";
import no from "@/messages/no.json";
import pl from "@/messages/pl.json";
import pt from "@/messages/pt.json";
import sk from "@/messages/sk.json";
import sv from "@/messages/sv.json";
import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import ProjectsService from "@/modules/Admin/Services/ProjectsService";
import loader from "@/modules/Shared/Mixins/loader";

export default {
  name: "TranslationsPage",
  components: {DataTable},
  mixins: [loader],
  data() {
    return {
      languages: [],
      selectedLanguage: 'en',
      translations: null,
      datatableConfig: [
          { 'field' : 'key', head : 'key', 'searchable' : true, 'sortable' : false},
          { 'field' : 'value', head : 'value', 'searchable' : true, 'sortable' : false},
          { 'field' : 'actions', 'type': 'actions', head : this.$t("geofences.geofences-messages.actions"), 'searchable' : false, 'sortable' : false }
      ],
      pagination: {},
    }
  },
  async created() {
    this.translations = {
        ca: this.processLanguageTranslations(ca),
        de: this.processLanguageTranslations(de),
        en: this.processLanguageTranslations(en),
        es: this.processLanguageTranslations(es),
        fr: this.processLanguageTranslations(fr),
        it: this.processLanguageTranslations(it),
        no: this.processLanguageTranslations(no),
        pl: this.processLanguageTranslations(pl),
        pt: this.processLanguageTranslations(pt),
        sk: this.processLanguageTranslations(sk),
        sv: this.processLanguageTranslations(sv)
    }

    this.languages = Object.keys(this.translations)
    this.pagination = {
        per_page: 50,
        total: this.selectedTranslation.length
    }
  },
  computed: {
    selectedTranslation() {
        return this.translations[this.selectedLanguage]
    }
  },
  methods: {
    async handleDeploy() {
        await this.loadAndNotify(async () => {
            await ProjectsService.build()
        })
    },
    handleAction(type, key){
        switch(type) {
            case 'edit':
                window.open(`https://app.lokalise.com/project/598231866086607eb85f69.46794187/?view=multi&search=${key}`, '_blank');
                break;
        }
    },
    processLanguageTranslations(translationObject) {
        const result = {}
        this.process(translationObject, result)
        return Object.entries(result).map(([key, value]) => ({key,value, uuid: key, actions: ['edit']}))
    },
    process(translationObject, result = {}, previousKey = '') {
        for (const [key, value] of Object.entries(translationObject)) {
            const finalKey = (previousKey ? `${previousKey}.${key}` : key).replaceAll('.', '::')
            if (typeof value === 'object' && value !== null) {
                this.process(value, result, finalKey)
            } else {
                result[finalKey] = value
            }
        }
    },
  }
};
</script>
